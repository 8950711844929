
import axios from "axios";
import { defineComponent, onMounted, ref } from "vue";
import state from "../state";

export default defineComponent({
  components: {
    state,
  },
  name: "PodcastView",
  props: {
    order: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const img = ref();

    onMounted(async () => {
      await state.fetchPodcast(props.order);
    });

    return {
      // You need to return the ref,
      // or it will not work.
      state,
    };
  },
});
