import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01e6a13f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"block"} }
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "club" }
const _hoisted_4 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "router-link",
        to: { name: 'Event', params: { eventId: _ctx.eventId, clubId: _ctx.clubId } }
      }, {
        default: _withCtx(() => [
          _createElementVNode("text", {
            class: "name",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibleFull=true))
          }, _toDisplayString(_ctx.name), 1),
          _createTextVNode("     "),
          _createElementVNode("text", _hoisted_3, _toDisplayString(_ctx.club), 1),
          _createTextVNode("  "),
          _createElementVNode("text", _hoisted_4, _toDisplayString(_ctx.fixDate(_ctx.from)), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}