
import axios from "axios";
import { defineComponent, onMounted, ref, onBeforeUnmount } from "vue";
import state from "../state";

export default defineComponent({
  components: {
    state,
  },
  name: "EventView",
  props: {
    eventId: {
      type: String,
      required: true,
    },
    clubId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const img = ref();
    let eventName = ref();
    let eventInfo = ref([]);

    onMounted(async () => {
      await state.fetchEvent(props.clubId, props.eventId);
      eventInfo.value = state.resultForEvent;
    });
    return {
      // You need to return the ref,
      // or it will not work.
      state,
      eventInfo,
    };
  },
});
