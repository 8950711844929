
import { ref } from 'vue'
import LayoutDefault from '../src/layouts/LayoutDefault.vue'
import navigation from '../src/components/CompNavigation.vue'

export default {
  name: 'app',

  components: {
    LayoutDefault,
    navigation
  },
  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}

