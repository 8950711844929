
  import { defineComponent, ref, onMounted, onUnmounted } from "vue";
  
  export default defineComponent({
    name: "FloatingButton",
    setup() {
      const isVisible = ref(false);
  
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        isVisible.value = (scrollTop < 6000 && scrollTop > 2000);
      };
  
      onMounted(() => {
        window.addEventListener("scroll", handleScroll);
      });
  
      onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll);
      });
  
      return {
        isVisible,
      };
    },

  props: {
    club: {
      type: Object as () => string,
      required: false,
    },
    date: {
      type: Object as () => [],
      required: false,
    },
  },
  options: {},
    computed: {},
  });
  