
// img(:src="`../assets/clubs/${icon}`")
// img(src="../assets/clubs/SVK.png")
import {
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  computed,
  onUpdated
} from "vue";
import moment from "moment";
//import { ClubModel } from "../../server/src/models/club";
import ClubsMap from "../components/ClubsMap.vue";
import state from "../state/index";
import axios from "axios";
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router';
export default defineComponent({
  components: {
    ClubsMap,
    state,
  },
  async setup(props) {
    const img = ref();
    onMounted(async () => {
      try {
        img.value =
          "data:image/jpg;base64," +
          `${(await axios.get(`/api/image/${props.imageId}`)).data.data}`;
      } catch (err) {
        console.error("chyba", err);
        // Handle error
      }
    });
    onUpdated(() => {
      // text content should be the same as current `count.value`
    });

    const op = ref();

    const visibleFull = ref(false);
    const selectedProduct = ref();
    const toggle = (event: any) => {
      op.value.toggle(event);
    };

    return { selectedProduct, toggle, visibleFull, state, img };
  },

  name: "CompEvent",
  props: {
    name: {
      type: Object as () => string,
      required: true,
    },
    club: {
      type: Object as () => string,
      required: true,
    },
    icon: {
      type: Object as () => string,
      required: true,
    },
    from: {
      type: Object as () => Date,
      required: true,
    },
    to: {
      type: Object as () => Date,
      required: true,
    },
    desc: {
      type: Object as () => String,
      required: true,
    },
    fb: {
      type: Object as () => String,
      required: false,
    },
    eventId: {
      type: Object as () => string,
      required: true,
    },
    clubId: {
      type: Object as () => string,
      required: true,
    },
    imageId: {
      type: Object as () => string,
      required: false,
    },
  },
  watch: {
    // It listens to the change in prop name
    imageId: function (val) {
 // print out when the name changes
    },
  },
  methods: {
    fixDate(d: Date) {
      return (
        "        " +
        moment(d).format("ddd") +
        "." +
        moment(d).format("DD") +
        " " +
        moment(d).format("MMM")
      );
    },
    loadData() {
      this.state.fetchEvent(this.clubId, this.eventId);
    },
    async loadImage() {
      const image = axios.get(`/api/image/${this.imageId}`);
      return "data:image/jpg;base64," + `${(await image).data.data}`;
    },
  },
});
