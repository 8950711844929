
import { ref, defineComponent, onMounted } from "vue";
import LayoutDefault from "../../layouts/LayoutDefault.vue";
import state from "../../state";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import ObjectID from "bson-objectid";


// as any)._.flatten();
export default defineComponent({
  name: "AdminViewHomeEvent",
  components: {
    LayoutDefault,
  },
  setup() {
    onMounted(async () => {
      await state.fetchClubs();
      if (state.adminResult.length < 1) {
        await state.fetchAll();
      }
    });

    //const image: any = axios.get("/api/image/62cd3dbadbf7ff0e8345b21b");
    const img = ref();

    const eventInfo = ref({ name: null, from: null, to: null, club: null, top: false, description: "", fb:"" , ra: ""});
    const podcastInfo = ref({ name: null, mix: null, interview: null, order: null})
    const display = true;
    const display2 = true;
    const showImage = false;
    const filters1 = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    return {
      state,
      filters1,
      display,
      display2,
      eventInfo,
      podcastInfo,
     // image,
      img,
      showImage,
    };
  },
  methods: {
    showInfo() {
      console.log({
        summary: "Info Message",
        detail: "Message Content",
        life: 3000,
      });
    },
    onSelectEvent(params: any) {
      // axios.post("/api/admin/upload",{params});
    },

    async uploadPodcast(file: any){
      const formData = new FormData();
      formData.append("file",file.files[0])
      const url = "/api/image/upload";
      const newId =  new ObjectID;
      console.log("CC");

      const fetchResponse = await fetch(`${url}/${newId}`,{
            method: "POST",
            headers: {
              // don't set Content-Type: multipart/form-data. It's set automatically by fetch (same things with axios)
            },
            body: formData

          });

    if(fetchResponse.status === 200) {
      console.log("ahoj", this.podcastInfo);
      const podcast = {
          name: this.podcastInfo.name,
          interview: this.podcastInfo.interview,
          order:this.podcastInfo.order,
          mix: this.podcastInfo.mix,
          podcastimage: newId,
          // fb: this.eventInfo.fb,
      }
      const newPodcast =  await axios.post("/api/podcast", { podcast });
    
        }
    },

    async uploadImage(file: any) {


      const formData = new FormData();
      formData.append("file", file.files[0]);

      const url = "/api/image/upload";
      const newId =  new ObjectID;
      const newId2 =  new ObjectID;

      const fetchResponse = await fetch(`${url}/${newId}`,{
            method: "POST",
            headers: {
              // don't set Content-Type: multipart/form-data. It's set automatically by fetch (same things with axios)
            },
            body: formData

          });

          // lamerina ale bude fungovat 
          if (this.eventInfo.top === true) {
            if (file.files.length =2 ) {
              const formData = new FormData();
                formData.append("file", file.files[1]);

                const url = "/api/image/upload";
                


                const fetchResponse = await fetch(`${url}/${newId2}`,{
                      method: "POST",
                      headers: {
                        // don't set Content-Type: multipart/form-data. It's set automatically by fetch (same things with axios)
                      },
                      body: formData

          });
            }

          }

       if(fetchResponse.status === 200) {
            const event = {
                name: this.eventInfo.name,
                from: this.eventInfo.from,
                to: this.eventInfo.to,
                club: this.eventInfo.club,
                active: true,
                image: newId,
                description: this.eventInfo.description,
                fb: this.eventInfo.fb,
                ra: this.eventInfo.ra,
                promoter: "admim",
                top: this.eventInfo.top,
                topimage:newId2
            }
          const newEvent =  await axios.post("/api/event", { event });
        
              if(newEvent.status === 200 ) {
                  const eventRegistration =  await axios.put(`/api/club/${(this.eventInfo.club as any).id}/${newEvent.data.id}`);
              }
           };


      // this.showImage = true;
      // const image = axios.get("/api/image/62b6db7fdc9b1ecfce5bb889");
      // this.img = "data:image/jpg;base64," + `${(await image).data.data}`;
    },


    myUploader(event: any) {
      axios.post("/api/admin/upload", { params: event });
      // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    },
    saveEvent(data: any) {
      const event = {
        name: data.name,
        from: data.from,
        to: data.to,
        club: data.club,
        activate: true,
      };
    },
  },
});
