
import { defineComponent, computed, ref } from "vue";
import moment from "moment";
//import * as fs from 'fs';

enum Color {
  Black,
  Silver,
}

function f(x: Color) {
  if (x === Color.Black) {
    return [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#212121",
          },
        ], 

      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2c2c2c",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#373737",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#3c3c3c",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#4e4e4e",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#3d3d3d",
          },
        ],
      },
    ];
  }

  if (x === Color.Silver) {
    return [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ];
  }
}
function fixDate(d: Date) {
  return (
    moment(d).format("ddd") +
    "." +
    moment(d).format("DD") +
    " " +
    moment(d).format("MMM")
  );
}
interface IPosition {
  lat: number;
  lng: number;
}

export default defineComponent({
  name: "ClubsMap",
  props: {
    center: {
      type: Object as () => IPosition,
      required: true,
    },
    markers: {
      type: Object as () => [any],
      required: true,
    },
    color: {
      type: Object as () => Color,
      required: true,
    },
  },

  data: () => {
    return { openedMarkerID: 0 };
  },
  computed: {
    option(): any {
      return {
        mapTypeControl: false,
        scaleControl: false,
        disableDefaultUI: false,
        scrollwheel: false,
        styles: f(this.color),
        fullscreenControl: false,
      };
    },
    // makeIcon(club: string): any {
    //     const link: string = "'../assets/clubs"+club+"icon.png'";
    //     return  "{ url: require("+ link +")}"
    // }
  },
  methods: {
    openMarker(id: number) {
      console.log('id',id)
      if (this.openedMarkerID === id) {
        this.openedMarkerID = 0;
      } else {
        this.openedMarkerID = id;
      }
    },

    // MarkersCoordinates(p: any):any{
    //     console.log("this.markers.map((obj: any) => ({position: obj.club.coordinates}));", this.markers.map((obj: any) => ({position: obj.club.coordinates})));
    //     return this.markers.map((obj: any) => ({position: obj.club.coordinates}));
    // }
    makeIconLink(name: string): any {
      // "{ url: require('../assets/clubs/FUHAicon.png')}";
      const link: any =
        "../assets/clubs/" + name.replace(/\s/g, "") + "icon.png";
      // if (fs.exists(link)) {
      //   console.log("IKONA NEEXISTUJE");
      // }
      //console.log( { url: require(link)});
      //return  { url: require(link)};
      //console.log(link);
      return { url: require(link) };
    },
  },
});
