
import { ref, defineComponent, onMounted, onBeforeUnmount } from "vue";
import LayoutDefault from "../../layouts/LayoutDefault.vue";
import state from "../../state";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import ObjectID from "bson-objectid";



export default defineComponent({
    name: "AdminViewLineup",
    components: {
      LayoutDefault,
    },
    setup() {
      onMounted(async () => {
        await state.fetchPersons();
        //admin lineup
        await state.fetchEasyEvents();
      });

    const personInfo = ref({ name: null, external_link: null, description: null});
    const lineUpInfo = ref({event: null, persons:[null] });

    return {
        personInfo,
        lineUpInfo,
        state,
    }
},
methods: {
  getPerson(){},

  async savePerson(){
    const person = {
                name: this.personInfo.name,
                external_link: this.personInfo.external_link,
                description: this.personInfo.description,
            }

    const newPerson = await axios.post("/api/lineup/savePerson", {person})
  },

  async saveLineup() {
    const lineup = {
        event: this.lineUpInfo.event.id,
        persons: this.lineUpInfo.persons.map((person: { id: any; }) => person.id), // Only include IDs
    };
    console.log("xxxx",lineup);
    try {
        const newLineup = await axios.post("/api/lineup/saveLineup", { lineup });
        // Handle successful response
    } catch (error) {
        // Handle error
    }
}

}})

