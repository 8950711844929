
import test from "node:test";
import { watchEffect, defineComponent, computed, ref,reactive } from "vue";
import state from "../state/index";


interface IQuery {
  club: string;
  dateRange: any;
}

interface DateRange {
  from: Date | null;
  to: Date | null;
}


const selectedClub = ref("");
const selectedDateRange = ref<DateRange>({
      from: null,
      to: null,
    });;

export default defineComponent({

  setup() {

    // const selectedClub = ref("");s
    // const selectedDateRange = ref("");


    const query: any = computed(() => {
      return { club: state.selectedClub, from: state.selectedDateRange.from, to: state.selectedDateRange.to};
    });
    state.fetchClubs();
    watchEffect(() =>
      state.updateQuery(selectedClub.value, selectedDateRange.value)
      
    );

    // watchEffect(() => {
    //   state.fetchEvents();
    // });
    return { query, selectedClub, selectedDateRange };
  },

  name: "CompFilter",
  props: {
    value: {
      type: Object as () => string,
      required: true,
    },
    clubs: {
      type: Object as () => [],
      required: true,
    },
  },
  options: {},
    computed: {},
  methods: {
    reset() {
      this.selectedDateRange = "";
      this.selectedClub = "";
    },
    formatDate(dateString: {from: any, to:any}) {
            const date = [new Date(dateString.from),new Date(dateString.to)];
                // Then specify how you want your dates to be formatted
            return [new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date[0]),new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date[1])] ;
        },
    test(dd: any) {
      if ( dd && dd[0]) {

      }
      return ""}    
    
  },
});
