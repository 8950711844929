
//            Event(:name="e.eventName", :club="e.clubName" :icon="e.clubIcon", :flayer="e.flayer")
//p(v-for="e in this.state.resultForEvents")

import { computed, defineComponent, onMounted, watch, watchEffect } from "vue";
import state from "../state";

import About from "../views/ViewAbout.vue";
import { flatten } from "array-flatten";
import { defineAsyncComponent } from "vue";
import { clearScreenDown } from "readline";
import { ref, onBeforeUnmount } from "vue";
import { defaultsDeep } from "lodash";

export default defineComponent({
  components: {},

  setup() {
    onMounted(async () => {
      findRows(screenSize.value);
      await state.fetchPodcasts();
      await state.fetchPodcastsPage(first.value, rows.value);
      loading.value = false;
    });
    const rows = ref();
    const first = ref();
    const loading = ref(true);
    const loadingDelay = ref(true);

    // state.fetchPodcastsPage(first.value, rows.value);
    // watchEffect(() =>
    //   state.fetchPodcastsPage(first.value, rows.value)

    // );

    // Create a reactive ref to store the screen size
    const screenSize = ref({ width: window.innerWidth, height: window.innerHeight });

    // Function to update the screen size
    watch(screenSize, (newSize, oldSize) => {
      // Run your specific function here
      handleScreenSizeChange(newSize, oldSize);
      findRows(screenSize.value);
      state.runFetchPodcastPage(first.value, rows.value);
    });

    // watch(loading, (newValue, oldValue) => {
    //  if (newValue == false)  {
    //   setTimeout(() => {
    //     loadingDelay.value = false;
    //   }, 500);
    //  }
    // });

    const handleScreenSizeChange = (
      newSize: { width: number; height: number },
      oldSize: { width: number; height: number }
    ) => {
      // Your specific function logic here
      console.log("Screen size changed:", newSize);
    };

    const updateScreenSize = () => {
      screenSize.value = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    };

    // Attach the updateScreenSize function to the window resize event
    onMounted(() => {
      window.addEventListener("resize", updateScreenSize);
    });

    // Remove the event listener when the component is unmounted
    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScreenSize);
    });

    // Your method that depends on the screen size
    const findRows = (size: { width: number; height: number }) => {
      first.value = 0;
      // Your logic based on the screen size
      if (size.width > 1000) {
        rows.value = 4;
      }
      if (size.width < 600) {
        rows.value = 2;
      }
      if (size.width < 400) {
        rows.value = 2;
      }
      console.log("tutu");
    };

    const onFirstChange = (value: number) => {
      first.value = value;
    };
    const onRowsChange = (value: number) => {
      loadingDelay.value = true;
      loading.value = true;
      state.runFetchPodcastPage(first.value, value.toString());
      loading.value = false;
    };
    const onPageChange = (value: number) => {
      console.log("page change", value);
    };

    watchEffect(() => {
      if (loading.value == false) {
        setTimeout(() => {
          loadingDelay.value = false;
        }, 500);
      }
    });

    return {
      // You need to return the ref,
      // or it will not work.
      state,
      screenSize,
      findRows,
      onFirstChange,
      onRowsChange,
      onPageChange,
      rows,
      first,
      loading,
      loadingDelay,
    };
  },
});
