
//            Event(:name="e.eventName", :club="e.clubName" :icon="e.clubIcon", :flayer="e.flayer")
//p(v-for="e in this.state.resultForEvents")

import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import state from "../state";

import { flatten } from "array-flatten";
import { defineAsyncComponent } from "vue";

const LocalEvent = defineAsyncComponent(() => import("../components/CompEvent.vue"));
export default defineComponent({
  components: {
    LocalEvent,
  },

  setup() {

    
    onMounted(async () => {
      if (state.resultForMap.length < 1) {
        await state.fetchEvents();
      }
    });

    return {
      // You need to return the ref,
      // or it will not work.
      state,
    };
  },
  beforeUnmount() {
    state.releaseEvent();
  },

  unmounted() {
  }
  
});
