
import { defineComponent, onMounted, onUnmounted, ref, watchEffect } from "vue";
import moment from "moment";

interface Image {
  src: string;
  caption?: string;
  date: Date | null;
}

export default defineComponent({
  name: "FullScreenGallery",
  props: {
    images: {
      type: Array as () => Image[],
      required: true,
    },
  },
  setup(props) {
    let autoplayInterval: number = 4000;
    const itemWidth = ref(0);
    const itemHeight = ref(0);
    const galleryContainer = ref<HTMLDivElement | null>(null);
    const loadDefault = () => {
      return props.images.length > 0 ? false : true;
    };
    const dont = ref(loadDefault());

    const currentImageIndex = ref(0);
    const showText = ref(false);
    const currentImage =
      props.images.length > 0 ? ref(props.images[currentImageIndex.value].src) : ref(0);
    const currentImageDate =
      props.images.length > 0 ? ref(props.images[currentImageIndex.value].date) : ref(0);
    const currentImageCaption =
      props.images.length > 0 ? ref(props.images[currentImageIndex.value].caption ?? "") : ref(0);

    const nextImage = () => {
      currentImageIndex.value = (currentImageIndex.value + 1) % props.images.length;
    };

    const prevImage = () => {
      currentImageIndex.value =
        (currentImageIndex.value - 1 + props.images.length) % props.images.length;
    };

    const calculateItemSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const containerWidth = screenWidth; // galleryContainer.value?.offsetWidth ?? 0
      // const numItems = images.value.length
      itemWidth.value = containerWidth; /// numItems
      itemHeight.value = screenHeight; //itemWidth.value / 1.5 // assume aspect ratio of 3:2
    };
    onMounted(() => {
      window.addEventListener("resize", calculateItemSize);

      setInterval(() => {
        nextImage();
        if (currentImageIndex.value >= props.images.length || !loadDefault) {
          prevImage();
        }
      }, autoplayInterval);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", calculateItemSize);
    });

    watchEffect(() => {
      if (props.images.length > 0) {
        currentImage.value = props.images[currentImageIndex.value].src;
        currentImageCaption.value = props.images[currentImageIndex.value].caption ?? "";
        currentImageDate.value = props.images[currentImageIndex.value].date;
        showText.value = Boolean(currentImageCaption.value);
        calculateItemSize();
      } else {
        showText.value = true;
        currentImageCaption.value = "Bratislava undeground advisor";
      }
    });

    return {
      currentImage,
      currentImageCaption,
      currentImageIndex,
      currentImageDate,
      nextImage,
      prevImage,
      showText,
      itemHeight,
      itemWidth,
    };
  },

  methods: {
    fixDate(d: Date) {
      return moment(d).format("ddd") + "." + moment(d).format("DD") + " " + moment(d).format("MMM");
    },
  },
});
