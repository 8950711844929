
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: 'StickyMenu',
  setup() {
    const isScrolled = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.pageYOffset > 0;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return { isScrolled };
  },
});
