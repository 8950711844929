
        import { defineComponent } from "vue";
    
    
        export default defineComponent({
            name: "LayoutDefault",
            data() {
                const mobileNav = false;
                const scrolledNav = false;
                const mobile = false;
                const windowWidth: number = window.innerWidth;
                return {
                    scrolledNav,
                    mobile,
                    mobileNav,
                    windowWidth,
                };
            },
            created() {
                window.addEventListener("resize", this.checkScreen);
                this.checkScreen();
            },
            mounted() {
                window.addEventListener("scroll", this.updateScroll);
            },
            methods: {
                toggleMobileNav() {
                    this.mobileNav = !this.mobileNav;
                },
                updateScroll() {
                    const scrollPosition = window.scrollY;
                    if (scrollPosition > 50) {
                        this.scrolledNav = true;
                        return;
                    }
                    this.scrolledNav = false;
                },
    
                checkScreen() {
                    this.windowWidth = window.innerWidth;
                    if (this.windowWidth <= 750) {
                        this.mobile = true;
                        return;
                    }
                    this.mobile = false;
                    this.mobileNav = false;
                    return;
                },
            },
        });
    