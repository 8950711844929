import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/denada_default.png'


const _withScopeId = n => (_pushScopeId("data-v-085c0994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fullscreen-gallery" }
const _hoisted_2 = { class: "lime" }
const _hoisted_3 = { class: "slider-container" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  style: {opacity:1}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "text-overlay"
}
const _hoisted_7 = { class: "caption" }
const _hoisted_8 = {
  key: 0,
  class: "topleft"
}
const _hoisted_9 = {
  key: 1,
  class: "topleftdefault"
}
const _hoisted_10 = {
  key: 2,
  class: "topright"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentImageIndex.value), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.images.length===0)
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.images, (img, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: index,
              src: img.src,
              class: _normalizeClass({ 'active': index === _ctx.currentImageIndex }),
              style: _normalizeStyle({ width: _ctx.itemWidth + 'px', height: _ctx.itemHeight + 'px' })
            }, null, 14, _hoisted_5))
          }), 128)),
      (_ctx.showText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.images.length>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.currentImageCaption), 1))
                : _createCommentVNode("", true),
              (_ctx.images.length===0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.currentImageCaption), 1))
                : _createCommentVNode("", true),
              (_ctx.images.length>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.fixDate(_ctx.currentImageDate)), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}