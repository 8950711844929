
import state from "../state";
import { defineComponent, onMounted, reactive, watch, ref, watchEffect } from "vue";

import LayoutDefault from "../layouts/LayoutDefault.vue";
import ViewAbout from "../views/ViewAbout.vue";
import ViewEvents from "../views/ViewEvents.vue";
import ViewPodcasts from "../views/ViewPodcasts.vue";
import axios from "axios";

enum Color {
  Black,
  Silver,
}

interface IPosition {
  lat: number;
  lng: number;
}

interface iEvent {
  name?: string;
  date?: string;
}

interface iClub {
  name?: string;
  position?: IPosition;
  event?: iEvent[];
}

async function getImage(imageId: string) {
  const image = axios.get(`/api/image/${imageId}`);
  return "data:image/jpg;base64," + `${(await image).data.data}`;
}

export default defineComponent({
  components: {
    LayoutDefault,
    ViewAbout,
    ViewEvents,
    ViewPodcasts,
  },
  setup() {
    const items: any = [
      {
        label: "File",
        icon: "pi pi-fw pi-file",
        items: [
          {
            label: "New",
            icon: "pi pi-fw pi-plus",
            items: [
              {
                label: "Bookmark",
                icon: "pi pi-fw pi-bookmark",
              },
              {
                label: "Video",
                icon: "pi pi-fw pi-video",
              },
            ],
          },
          {
            label: "Delete",
            icon: "pi pi-fw pi-trash",
          },
          {
            separator: true,
          },
          {
            label: "Export",
            icon: "pi pi-fw pi-external-link",
          },
        ],
      },
      {
        label: "Edit",
        icon: "pi pi-fw pi-pencil",
        items: [
          {
            label: "Left",
            icon: "pi pi-fw pi-align-left",
          },
          {
            label: "Right",
            icon: "pi pi-fw pi-align-right",
          },
          {
            label: "Center",
            icon: "pi pi-fw pi-align-center",
          },
          {
            label: "Justify",
            icon: "pi pi-fw pi-align-justify",
          },
        ],
      },
      {
        label: "Users",
        icon: "pi pi-fw pi-user",
        items: [
          {
            label: "New",
            icon: "pi pi-fw pi-user-plus",
          },
          {
            label: "Delete",
            icon: "pi pi-fw pi-user-minus",
          },
          {
            label: "Search",
            icon: "pi pi-fw pi-users",
            items: [
              {
                label: "Filter",
                icon: "pi pi-fw pi-filter",
                items: [
                  {
                    label: "Print",
                    icon: "pi pi-fw pi-print",
                  },
                ],
              },
              {
                icon: "pi pi-fw pi-bars",
                label: "List",
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        icon: "pi pi-fw pi-calendar",
        items: [
          {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: "Save",
                icon: "pi pi-fw pi-calendar-plus",
              },
              {
                label: "Delete",
                icon: "pi pi-fw pi-calendar-minus",
              },
            ],
          },
          {
            label: "Archieve",
            icon: "pi pi-fw pi-calendar-times",
            items: [
              {
                label: "Remove",
                icon: "pi pi-fw pi-calendar-minus",
              },
            ],
          },
        ],
      },
      {
        label: "Quit",
        icon: "pi pi-fw pi-power-off",
      },
    ];
    const mapColor: Color = Color.Silver;
    const loadingDelay = ref(true);
    onMounted(async () => {
      if (state.tops.length < 1) {
        await state.fetchAll();
      }
      if (state.resultForMap.length < 1) {
        await state.fetchEvents();
      }

      if (state.isReady == true) {
        setTimeout(() => {
          loadingDelay.value = false;
        }, 100);
      }
      // if (state.tops.length < 1 ) {
      //   await state.fetchAll();
      // }
    });

    watchEffect(() => {
      if (!state.isReady) {
        setTimeout(() => {
          loadingDelay.value = false;
        }, 1200);
      }
    });
    return {
      state,
      mapColor,
      items,
      loadingDelay,
    };
  },
});
